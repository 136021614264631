import React, { Fragment, useEffect, useRef, useState } from "react";
import Hammer from 'hammerjs';
import "flickity-as-nav-for";
import Flickity from "react-flickity-component";
import ThreeDbutton from "../../assets/icons/3d-button.svg";
import ArrowLeftIcon from "../../assets/icons/arrow-left-small.svg";
import ArrowRightIcon from "../../assets/icons/arrow-right-small.svg";
import FavouriteOn from "../../assets/icons/favourite-dark-on.svg";
import Favourite from "../../assets/icons/favourite-dark.svg";
import Share from "../../assets/icons/share-dark-icon.svg";
import Button from "../../components/Button";
import rudderStackEvents from "../../services/rudderstack-events";
import {
    constructImageUrl,
    formatePrice, getShareURL, isMobileDevice,
    toInches, isHTML, getArtworkStateStatusClass, getArtworkStateStatus, isTypeMuseum,
    IsSafari,
    constructVideoUrlForPoster, constructCFUrl
} from '../../utils';
import ModelViewer from "../3dArtworkViewer/Viewer";
import AboutVideo from "../AboutVideo";
import AudioWrapper from "../AudioWrapper";
import CurateEnquireSideBar from "../CurateEnquireSideBar";
import ImageLoader from "../ImageLoader";
import ImageZoom from "../ImageZoom";
import ShareBtn from "../ShareBtn";
import SpinningLoader from "../SpinningLoader";
import { Heading, Label, Paragraph } from "../Typography2";
import "./styles.scss";
import { IArtwork2DCarouselProps } from "./types";
import Spinner from '../Spinner'

const Artwork2DCarousel: React.FC<IArtwork2DCarouselProps> = ({
    handleClose,
    artworks,
    selectedArtwork,
    on3dButtonClick,
    setArtwork2dModal,
    isFavourite,
    isSidebar,
    onFavourite,
    isArtworkActiveIn3dFrom2dDetailedView,
    onClickSlider,
    exhibitionsId,
    galleryName,
    handleSelectedArtwork,
    exhibitiondId,
    is3dViewOpen,
    setIs3dViewOpen,
    progress,
    isArtworkModal,
    setToggleLogin,
    isLoggedIn,
    isOldExhibition,
    handleArtworkClick,
    excludedTax,
    preview,
    isMuseumType,
    handleAuthModal,
    isListView,
    isArt = true
}) => {
    const [dimensionsUnit, setDimensionsUnit] = useState("cm");
    const [showDescription, setShowDescription] = useState(false);
    const [showEnquire, setShowEnquire] = useState(false)
    const [is2dModel, set2DModel] = useState(false)
    const [photos3DModels, setPhotos3DModels] = useState(null);
    const detailsRef = useRef(null);
    const bottomNavRef = useRef(null);
    const sideBarRef = useRef(null)
    const [pinchState, setPinchState] = useState(null);
    const [isLoaded, setLoaded] = useState(false);
    const [actionDisabled, setActionsDisabled] = useState(false);
    const [artworkPhotos, setArtworkPhotos] = useState([]);
    const [is3dActive, setIs3dActive] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [totalTime, setTotalTime] = useState(0);
    const [isAnime, setIsAnime] = useState(false);
    const [isActive, setActive] = useState(false);
    const [is3D, setIs3D] = useState(false);
    const artworkLoadingTimeoutRef = useRef<any>(null);
    const previewArtworkLoadingTimeoutRef = useRef<any>(null);

    const sliderRef = useRef(null);
    const videoRef = useRef(null);

    const handleDimensionsToggle = () => {
        if (dimensionsUnit === "cm") {
            setDimensionsUnit("in");
        } else {
            setDimensionsUnit("cm");
        }
    };

    useEffect(() => {
        setLoaded(false);
        if (is3dActive && (isOldExhibition || selectedArtwork?.data.exhibitionSceneId)) {
            handleArtworkClick(selectedArtwork?.data.id);
            setIs3dActive(true)
            handleModelClick(false);
        }
    }, [selectedArtwork?.data?.id]);

    useEffect(() => {
        let timeout;
        if (sliderRef.current) {
            artworkLoadingTimeoutRef.current = setTimeout(() => {
                setLoaded(true);
            }, 1000);
            timeout = setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 0);
        }
        return () => {
            if(artworkLoadingTimeoutRef.current){
                clearTimeout(timeout);
                clearTimeout(artworkLoadingTimeoutRef.current);
            }
        }
    }, [sliderRef.current])

    useEffect(() => {
        if(preview && artworkPhotos?.length > 0) {
            previewArtworkLoadingTimeoutRef.current = setTimeout(() => {
                setLoaded(true);
            }, 1000);
        }
        return () => {
            if(previewArtworkLoadingTimeoutRef.current){
                clearTimeout(previewArtworkLoadingTimeoutRef.current)
            }
        }
    }, [artworkPhotos, preview])


    useEffect(() => {
        if ((is3dViewOpen && progress === 0) || (progress > 0 && progress < 100)) {
            setActionsDisabled(true);
        } else {
            setActionsDisabled(false);
        }
    }, [is3dViewOpen, progress])

    const getVideoSource = (videoData) => {
        const isOnMobileDevice = IsSafari() || isMobileDevice() || window.innerWidth <= 768;
        if(isOnMobileDevice) {
          const mobileVideoSource = constructVideoUrlForPoster(videoData);
          return [
            mobileVideoSource
          ]
        }

        return [
          constructVideoUrlForPoster(videoData, '4000'),
          constructVideoUrlForPoster(videoData, '1080'),
          constructVideoUrlForPoster(videoData, '720'),
        ]
      }

    const loadArtwork = async (artwork) => {
        try {
            setArtworkPhotos([]);
            const tempPhotos = [];
            const mediaObject = artwork.data.type === "2d" ? artwork.data.image : artwork.data.type === "3d" ? artwork.data.thumbnail : artwork.data.videoThumbnail;

            const { CFURL, name, signature } = mediaObject.data;
            if (artwork.data.type !== '2d' && artwork.data.type !== "3d") {
                tempPhotos.push({
                    small: CFURL + '120_' + name + signature,
                    url: getVideoSource(artwork.data.video),
                    thumbnail: (constructImageUrl(mediaObject, (isMobileDevice() ? "1280" : "1920"))),
                    isImage: false,
                    type: artwork.data.type,
                    selected: true
                })
            }
            if (artwork.data.type === "3d") {
                console.log('artwork = ', artwork)
                setPhotos3DModels({
                    small: CFURL + '120_' + name + signature,
                    url: constructCFUrl(artwork.data.vorticLowBundle),
                    isImage: false,
                    type: artwork.data.type,
                    selected: true
                })
                setIs3D(true);
            }
            // ? Don't remove this for now :)
            tempPhotos.push({
                small: constructImageUrl(mediaObject, "120"),
                large: constructImageUrl(mediaObject, "1920"),
                type: artwork.data.type,
                isImage: true,
                selected: false
            })
            artwork?.data?.photos?.map((item, index) => {
                tempPhotos.push({
                    small: constructImageUrl(item, "120"),
                    large: constructImageUrl(item, "1920"),
                    type: artwork.data.type,
                    isImage: true,
                    selected: index === 0
                })
            })
            setArtworkPhotos(tempPhotos);
        } catch (e) {

        }
        setIs3dActive(true);
        setLoaded(true);
    }
    useEffect(() => {
        if(selectedArtwork?.data?.id) {
            loadArtwork(selectedArtwork);
        }
    }, [selectedArtwork?.data?.id])

    const handleNextClick = () => {
        set2DModel(false)
        setPhotos3DModels(null)
        setIs3dViewOpen(false)
        const currentArtworkIndex = artworks.findIndex(item => item.data.id === selectedArtwork?.data.id);
        if(currentArtworkIndex < artworks.length - 1){
            setArtwork2dModal(artworks[currentArtworkIndex + 1].data.id)
        } else {
            setArtwork2dModal(artworks[0].data.id)
        }
    }

    const handlePrevClick = () => {
        set2DModel(false)
        setPhotos3DModels(null)
        setIs3dViewOpen(false)
        const currentArtworkIndex = artworks.findIndex(item => item.data.id === selectedArtwork?.data.id);
        if(currentArtworkIndex === 0){
            setArtwork2dModal(artworks[artworks.length - 1].data.id)
        } else {
            setArtwork2dModal(artworks[currentArtworkIndex - 1].data.id)
        }
    }

    const handleModelClick = (val: boolean) => {
        set2DModel(val);
    }

    const dimensionsInCM = `${parseFloat(selectedArtwork && selectedArtwork.data.height).toFixed(1)} x ${parseFloat(selectedArtwork && selectedArtwork.data.width).toFixed(1)} x ${parseFloat(selectedArtwork && selectedArtwork.data.depth).toFixed(1)} cm`;
    const dimensionsInIN = `${toInches(selectedArtwork && selectedArtwork.data.height)} x ${toInches(selectedArtwork && selectedArtwork.data.width)} x ${toInches(selectedArtwork && selectedArtwork.data.depth)} in`;
    const priceUnavailable = !selectedArtwork?.data?.isForSale || selectedArtwork?.data?.salesStatus === "price on application" || selectedArtwork?.data?.salesStatus === "sold" || selectedArtwork?.data?.salesStatus === "reserved";
    const noMuseum = isTypeMuseum(selectedArtwork?.data?.organisation?.data?.type);

    const isForSale = getArtworkStateStatus(selectedArtwork?.data?.salesStatus, selectedArtwork?.data?.isForSale);

    const loginPage = () => {
        if (isLoggedIn) {
            onFavourite();
        } else {
            setToggleLogin(true);
        }
    }

    useEffect(() => {
        if (isArtworkModal === false) {
            setPinchState('unzoom');
        }
    }, [isArtworkModal])

    const handleSwipeLeft = () => {
        if (isMobileDevice()) {
            const carousel = document.querySelector('.artwork-2d-carousel__slider');
            // @ts-ignore
            carousel.querySelector('.flickity-prev-next-button.next')?.click();
        }
    }

    const handleSwipeRight = () => {
        if (isMobileDevice()) {
            const carousel = document.querySelector('.artwork-2d-carousel__slider');
            // @ts-ignore
            carousel.querySelector('.flickity-prev-next-button.previous')?.click();
        }
    }

    const handlePinch = (e) => {
        if (e.scale < 1) {
            setPinchState('unzoom');
        } else if (e.scale > 1) {
            setPinchState('zoom');
        } else {
            setPinchState(null);
        }
    }

    useEffect(() => {
        if (isMobileDevice()) {
            if (pinchState === 'zoom') {
                const image = document.querySelector('.mobile-artwork-wrapper.is-selected');
                const selectedIndex = image.id.split("-")[1];
                if (selectedIndex) {
                    setTimeout((e) => {
                        // @ts-ignore
                        handleSelectedArtwork(true, artworkPhotos, selectedIndex);
                        e.stopPropagation();
                    }, 100)
                }
            }
        }
    }, [pinchState])

    useEffect(() => {
        // const myElement = document.querySelector('.artwork-2d-carousel__slider > .carousel');
        // if (isMobileDevice() && !!myElement) {
        //     var mc = new Hammer.Manager(myElement);

        //     mc.add(new Hammer.Pan({ threshold: 0, pointers: 0 }));

        //     mc.add(new Hammer.Swipe()).recognizeWith(mc.get('pan'));
        //     mc.add(new Hammer.Rotate({ threshold: 0 })).recognizeWith(mc.get('pan'));
        //     mc.add(new Hammer.Pinch({ threshold: 0 })).recognizeWith([mc.get('pan'), mc.get('rotate')]);

        //     mc.add(new Hammer.Tap({ event: 'doubletap', taps: 2 }));
        //     mc.add(new Hammer.Tap());

        //     mc.on("swipeleft", handleSwipeLeft);
        //     mc.on("swiperight", handleSwipeRight);
        //     // mc.on("pinchstart pinchmove", handlePinch);
        // }
    }, [document.querySelector('.artwork-2d-carousel__slider > .carousel')])

      const renderDescription = (description) => {
        if(isHTML(description)) {
            return <p className="paragraph" dangerouslySetInnerHTML={{ __html: description }} />
        }
        else {
            return <Paragraph value={description} />
        }
      }

    const openFullscreen = () => {
        if (videoRef.current.paused) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        videoRef.current.controls;
        videoRef.current.play();
        // setTimeout(() => {
        if (videoRef.current.requestFullscreen) {
            videoRef.current.requestFullscreen();
        } else if (videoRef.current.webkitRequestFullscreen) {
            /* Safari */
            videoRef.current.webkitRequestFullscreen();
        } else if (videoRef.current.msRequestFullscreen) {
            /* IE11 */
            videoRef.current.msRequestFullscreen();
        }
        // }, 0)
        } else {
        videoRef.current.pause();
        }
    };

    const handleNavImageClick = () => {
        if(!isListView){
            onClickSlider();
        }
        if(is3dViewOpen){
            setIs3dViewOpen(false);
        }
        if(is2dModel){
            handleModelClick(false);
        }
    }


    return (
        <div
            ref={bottomNavRef}
            className={`artwork-2d-carousel-wrapper ${is3dViewOpen ? `artwork-2d-carousel_3dview ${is3dActive ? 'active' : ''}` : ''} ${preview ? 'artwork-2d-carousel-wrapper--preview' : ''}`}>
            <div
                className={`artwork-2d-carousel__overlay`}
                onClick={() => handleClose()}
            />

            <div className="artwork-2d-carousel__content">
                {!preview && <div className="artwork-2d-carousel__header">
                    <div className="curate-artwork-middle">
                        <div
                            className={`artwork-actions margin-left-zero ${isSidebar ? "artwork-actions--sidebar-open" : ""}`}>
                            <button className="action-btn share back-artworks" onClick={handleClose}>
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.4496 16.9498L8.05835 7.5585" stroke="#1A1A1A" strokeLinecap="square" strokeLinejoin="round" />
                                    <path d="M7.69539 16.8046L7.55025 7.05028L17.3046 7.19542" stroke="#1A1A1A" strokeLinecap="square" strokeLinejoin="round" />
                                </svg>

                                <Label className="font-family detail-buttons" value={`Back to all ${isArt ? 'Artworks' : 'Objects'}`} />
                                <Label className="responsive-btn detail-buttons" value={`All ${isArt ? 'Artworks' : 'Objects'}`} />
                            </button>
                        </div>
                        <div className={`artwork-actions ${isSidebar ? "artwork-actions--sidebar-open" : ""} ${actionDisabled ? 'artwork-actions--disabled' : ''}`}>
                            <button className="action-btn share prev-btn" onClick={handlePrevClick}>
                                <img src={ArrowLeftIcon} alt="ArrowLeftIcon" />
                                <Label className="font-family detail-buttons" value={`Previous ${isArt ? 'Artwork' : 'Object'}`} />
                                <Label className="responsive-btn detail-buttons" value="Previous" />
                            </button>
                            <button className="action-btn reverse" onClick={handleNextClick}>
                                <Label className="font-family detail-buttons" value={`Next ${isArt ? 'Artwork' : 'Object'}`} />
                                <Label className="responsive-btn detail-buttons" value="Next" />
                                <img
                                    src={ArrowRightIcon}
                                    alt="ArrowRightIcon"
                                />
                            </button>
                        </div>
                    </div>
                </div>}

                <div className={`artwork-2d-carousel__body`}>
                    <div ref={sideBarRef} className={`artwork-2d-carousel__body__left`}
                    >
                        {
                            preview && !selectedArtwork && <Spinner />
                        }
                        {
                            showEnquire ?
                                <CurateEnquireSideBar
                                    activeArtworkId={selectedArtwork?.data.id}
                                    exhibitionsId={exhibitionsId}
                                    galleryName={galleryName}
                                    handleAuthModal={handleAuthModal}
                                    handleCancel={() => { setShowEnquire(false) }}
                                />
                                :
                                <>
                                    <div className="artwork-2d-carousel__body__left__header">
                                        <Heading className={"detail-header"}>
                                            {selectedArtwork?.data?.artist?.data?.name ? `${selectedArtwork?.data?.artist?.data?.name}:` : ""}&nbsp;
                                            {selectedArtwork?.data?.title ? selectedArtwork?.data?.title.concat(",") : ''}
                                            <span>
                                                {selectedArtwork?.data?.yearCreated ? selectedArtwork?.data?.yearCreated : ""}
                                            </span>
                                        </Heading>
                                        <Paragraph className={(!isMobileDevice() && window.innerWidth > 990) ? "grey-400" : ""}>
                                            <span> {selectedArtwork?.data.medium}</span>
                                        </Paragraph>
                                        {(!!selectedArtwork?.data?.height || !!selectedArtwork?.data?.width || !!selectedArtwork?.data?.depth) && <Paragraph className={"dimension" + (!isMobileDevice() && window.innerWidth > 990) && "grey-400"}>
                                            <>
                                                <span>
                                                    {`${dimensionsUnit === "in" ? dimensionsInIN : dimensionsInCM}`}
                                                    {
                                                        <button
                                                            className="gray unit-btn"
                                                            onClick={handleDimensionsToggle}
                                                        >
                                                            <span className={"dimension" + ((!isMobileDevice() && window.innerWidth > 990) ? "grey-400" : "")}>    / </span>
                                                            <a>
                                                                <span className={"dimension" + ((!isMobileDevice() && window.innerWidth > 990) ? "grey-400" : "")}>{dimensionsUnit === "in" ? "cm" : "in"}</span>
                                                            </a>
                                                        </button>
                                                    }
                                                </span>
                                            </>
                                        </Paragraph>}
                                        {!!(selectedArtwork?.data?.artworkCatalogueNumber && selectedArtwork?.data?.artworkCatalogueNumber.trim()) ?
                                            <Paragraph className="inventory">

                                                <span className={((!isMobileDevice() && window.innerWidth > 990) ? "grey-400" : "")}>
                                                    {selectedArtwork?.data.artworkCatalogueNumber || ""}
                                                </span>
                                            </Paragraph>
                                            : ""}
                                        {
                                            !isMuseumType &&
                                            <div className="artowrk-info">
                                                {
                                                    selectedArtwork?.data.isForSale &&
                                                    <div className="operations">
                                                    {
                                                        !priceUnavailable && !!selectedArtwork?.data.price && Number(selectedArtwork?.data.price) !== 0 &&
                                                        <Paragraph>
                                                            <span className="black-color">
                                                                <strong>Price: </strong>
                                                                <span>
                                                                    {formatePrice(selectedArtwork?.data?.price, selectedArtwork?.data?.currency)}
                                                                </span>
                                                                <span className="tax">{!!excludedTax ? "(ex tax)" : ''}</span>
                                                            </span>
                                                        </Paragraph>
                                                    }
                                                        <Paragraph className="gray capitalize">
                                                            <Paragraph>
                                                                <span
                                                                    className={`dot ${getArtworkStateStatusClass(selectedArtwork?.data.salesStatus)}`}></span>
                                                                    {selectedArtwork?.data.salesStatus}
                                                            </Paragraph>
                                                        </Paragraph>
                                                    </div>
                                                }
                                                <div onClick={() => {
                                                    setShowEnquire(true);
                                                    rudderStackEvents.onArtworkEnquiry(selectedArtwork?.data?.id)
                                                }} className={`enquire-container ${preview ? 'enquire-btn-disabled' : ''}`}>
                                                    <Paragraph className="enquire-text" value={"Enquire"} />
                                                </div>
                                            </div>
                                        }
                                        <div className={`audio-wrapper`}>
                                            <AudioWrapper
                                                artworkId={selectedArtwork?.data?.id}
                                                exhibitiondId={exhibitiondId}
                                                playing={playing}
                                                audioUrl={selectedArtwork?.data?.artworkAudio}
                                                currentTime={currentTime}
                                                totalTime={totalTime}
                                                setPlaying={setPlaying}
                                                setCurrentTime={setCurrentTime}
                                                setTotalTime={setTotalTime}
                                                isAnime={isAnime}
                                                isArtwork2dModal
                                                setIsAnime={setIsAnime}
                                                isActive={isActive}
                                                setActive={setActive}
                                                isGuiding={false}
                                                secondary
                                            />
                                        </div>
                                        {selectedArtwork?.data?.description && selectedArtwork?.data?.description.trim() !== "" &&
                                            <div ref={detailsRef}
                                                className={`description ${selectedArtwork?.data?.artworkAudio !== "" ? 'description--audio' : (isForSale ? 'description--for-sale' : '')}`}
                                                style={{ lineHeight: 16 }}>
                                                {renderDescription(selectedArtwork?.data?.description)}
                                            </div>}
                                    </div>
                                    <div className="artwork-2d-carousel__body__left__footer">
                                        <div className="curate-artwork-middle">
                                            <Button onClick={() => {
                                                setShowDescription(!showDescription);
                                                rudderStackEvents && rudderStackEvents.onArtworkDetail(selectedArtwork?.data?.id, showDescription ? "hide_artwork_description" : "show_artwork_description")
                                            }}
                                                className="button button-dark description-btn footer-3d-carousel-btn footer-font"
                                                iconType={showDescription ? "carret-on" : "carret-off"}
                                                type="secondary"
                                                value={showDescription ? "Close Description" : "Artwork Description"} />
                                            <div
                                                className={`artwork-actions ${isSidebar ? "artwork-actions--sidebar-open" : ""
                                                    }`}
                                            >
                                                <div className="action-btn center share">
                                                    <img src={Share} alt="share" />
                                                    <Label className="font-family detail-buttons" value={`Share ${isArt ? 'Artwork' : 'Object'}`} />
                                                    <ShareBtn chainLink={`https://app.vortic.io/artwork/${selectedArtwork?.data?.id}`} entityId={selectedArtwork?.data?.id || selectedArtwork?.data?.slug} up shareUrl={getShareURL(
                                                        "artwork",
                                                        selectedArtwork?.data?.id || selectedArtwork?.data?.slug
                                                    )
                                                    } />
                                                </div>
                                                <button className="action-btn center" onClick={loginPage}>
                                                    <img
                                                        src={isFavourite ? FavouriteOn : Favourite}
                                                        alt="favourite"
                                                    />
                                                    <Label className="font-family detail-buttons" value={`Bookmark ${isArt ? 'Artwork' : 'Object'}`} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={"hidden-artwork-details-modal" + (showDescription ? " active" : "")}>

                                        <div className="artwork-2d-carousel__body__left__header">
                                            <Heading>
                                                {selectedArtwork?.data?.artist?.data?.name ? selectedArtwork?.data?.artist?.data?.name : ""}:&nbsp;
                                                {selectedArtwork?.data?.title ? selectedArtwork?.data?.title?.concat(",") : ''}
                                                <span>
                                                    {selectedArtwork?.data?.yearCreated ? selectedArtwork?.data?.yearCreated : ""}
                                                </span>
                                            </Heading>
                                            {
                                            !isMuseumType &&
                                            <div className="artowrk-info">
                                                {
                                                    selectedArtwork?.data.isForSale &&
                                                    <div className="operations">
                                                    {
                                                        !priceUnavailable && !!selectedArtwork?.data.price && Number(selectedArtwork?.data.price) !== 0 &&
                                                        <Paragraph>
                                                            <span className="black-color">
                                                                <strong>Price: </strong>
                                                                <span>
                                                                    {formatePrice(selectedArtwork?.data?.price, selectedArtwork?.data?.currency)}
                                                                </span>
                                                                <span className="tax">{!!excludedTax ? "(ex tax)" : ''}</span>
                                                            </span>
                                                        </Paragraph>
                                                    }
                                                        <Paragraph className="gray capitalize">
                                                            <Paragraph>
                                                                <span
                                                                    className={`dot ${getArtworkStateStatusClass(selectedArtwork?.data.salesStatus)}`}></span>
                                                                    {selectedArtwork?.data.salesStatus}
                                                            </Paragraph>
                                                        </Paragraph>
                                                    </div>
                                                }
                                                <div onClick={() => {
                                                    setShowEnquire(true);
                                                    rudderStackEvents.onArtworkEnquiry(selectedArtwork?.data?.id)
                                                }} className={`enquire-container ${preview ? 'enquire-btn-disabled' : ''}`}>
                                                    <Paragraph className="enquire-text" value={"Enquire"} />
                                                </div>
                                            </div>
                                        }

                                            <Paragraph className="medium-mob">
                                                <>
                                                    {selectedArtwork?.data.medium}
                                                </>
                                            </Paragraph>
                                            {(!!selectedArtwork?.data?.height || !!selectedArtwork?.data?.width || !!selectedArtwork?.data?.depth) && <Paragraph className="dimensions-text dimension-mob">
                                                <>
                                                    {`${dimensionsUnit === "in" ? dimensionsInIN : dimensionsInCM
                                                        } / `}
                                                    {
                                                        <button
                                                            className="gray unit-btn"
                                                            onClick={handleDimensionsToggle}
                                                        >
                                                            {dimensionsUnit === "in" ? "cm" : "in"}
                                                        </button>
                                                    }
                                                </>
                                            </Paragraph>}
                                            <Paragraph className="inventory-mob">
                                                <>
                                                    {selectedArtwork?.data.artworkCatalogueNumber || ""}
                                                </>
                                            </Paragraph>

                                            {selectedArtwork?.data?.description && selectedArtwork?.data?.description.trim() !== "" &&
                                                <div className="description">
                                                    {renderDescription(selectedArtwork?.data.description)}
                                                </div>}
                                            <div className={`audio-wrapper`}>
                                                <AudioWrapper
                                                    artworkId={selectedArtwork?.data?.id}
                                                    exhibitiondId={exhibitiondId}
                                                    playing={playing}
                                                    audioUrl={selectedArtwork?.data?.artworkAudio}
                                                    currentTime={currentTime}
                                                    totalTime={totalTime}
                                                    setPlaying={setPlaying}
                                                    setCurrentTime={setCurrentTime}
                                                    setTotalTime={setTotalTime}
                                                    isAnime={isAnime}
                                                    isArtwork2dModal
                                                    setIsAnime={setIsAnime}
                                                    isActive={isActive}
                                                    setActive={setActive}
                                                    isGuiding={false}
                                                    secondary
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }

                    </div>
                    {
                        <div
                            className={`artwork-2d-carousel__body__right ${!!isArtworkActiveIn3dFrom2dDetailedView ? `artwork-2d-carousel__body__end` : ''}`}
                        >
                            {/* { */}
                            <>
                                <div
                                    className={`artwork-2d-carousel__showcase ${is3dViewOpen ? "hide" : ""}`}>
                                    <div className={`artwork-2d-carousel__slider ${(isLoaded && artworkPhotos.length > 0) ? 'slider--loaded' : ''}`}>
                                        {artworkPhotos.length > 0 && <Flickity
                                            className={"carousel carousel-main"}
                                            elementType={"section"}
                                            flickityRef={c => sliderRef.current = c}
                                            options={{
                                                pauseAutoPlayOnHover: false,
                                                wrapAround: true,
                                                prevNextButtons: isMobileDevice(),
                                                pageDots: false,
                                                contain: true,
                                                draggable: is3D ? artworkPhotos?.length > 2 : artworkPhotos?.length > 1
                                            }}
                                        >
                                            {artworkPhotos?.length > 0 &&
                                                artworkPhotos.map((photo, index) => {
                                                    return (
                                                        <Fragment key={index}>
                                                            {
                                                                (!isMobileDevice() || window.innerWidth > 990) ?
                                                                    <div className="artwork-2d-carousel__figure" key={index}>
                                                                        {
                                                                            photo.isImage ?
                                                                                <ImageZoom
                                                                                    className={'main2d-image '}
                                                                                    zoomType={'click'}
                                                                                    src={photo?.large?.replace('1920_', '1080_')}
                                                                                    zoomSrc={photo.large}
                                                                                />
                                                                                :
                                                                                <div className="artwork-2d-carousel__video-wrapper">
                                                                                    {/* <video controls src={photo.url}  poster={photo?.thumbnail}  />
                                                                                    <img className="artwork-2d-carousel__video-thumbnail" src={photo?.thumbnail} alt="thumbnail" /> */}
                                                                                    <AboutVideo
                                                                                        videoRef={videoRef}
                                                                                        onClick={(e) => { e.stopPropagation(); openFullscreen() }}
                                                                                        poster={photo?.thumbnail}
                                                                                        image={photo.url}
                                                                                        classNames="thumbnail-view"
                                                                                        src={photo.url}
                                                                                    />
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                    :
                                                                    <div className="mobile-artwork-wrapper" id={`2Dcarousel-${index}`}>
                                                                        <div className="artwork-2d-carousel__figure" key={index}>
                                                                            {
                                                                                photo.isImage ?
                                                                                    // @ts-ignore
                                                                                    <ImageLoader imgUrls={[photo?.large?.replace('1920_', '1080_')]} onClick={() => handleSelectedArtwork(true, artworkPhotos, index)} />
                                                                                    :
                                                                                    <div className="artwork-2d-carousel__video-wrapper">
                                                                                        {/* <video controls src={photo.url} poster={photo?.thumbnail} />
                                                                                        <img className="artwork-2d-carousel__video-thumbnail" src={photo?.thumbnail} alt="thumbnail" /> */}
                                                                                        <AboutVideo
                                                                                            videoRef={videoRef}
                                                                                            onClick={(e) => { e.stopPropagation(); openFullscreen() }}
                                                                                            poster={photo?.thumbnail}
                                                                                            image={photo.url}
                                                                                            classNames="thumbnail-view"
                                                                                            src={photo.url}
                                                                                        />
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </Fragment>
                                                    );
                                                })}
                                        </Flickity>}
                                        <SpinningLoader />
                                    </div>

                                    {artworkPhotos.length > 0 && <div className={'artwork-2d-model' + (!is3dViewOpen && is2dModel ? ' active' : '')}>
                                        {/* @ts-ignore */}
                                        {is2dModel && photos3DModels && photos3DModels?.url && <ModelViewer id={selectedArtwork?.data.id}
                                            onFloor={selectedArtwork?.data.onFloor}
                                            // @ts-ignore
                                            modelUrl={photos3DModels.url}
                                        />}
                                    </div>}
                                </div>
                            </>
                            {/* } */}
                            <section className={`artwork-2d-carousel__nav ${actionDisabled ? 'artwork-2d-carousel__nav--disabled' : ''}`}>
                                {artworkPhotos.length > 0 && <Flickity
                                    className={"carousel artwork-2d-carousel__list"}
                                    options={{
                                        // @ts-ignore
                                        asNavFor: '.carousel-main',
                                        contain: true,
                                        pageDots: false,
                                        prevNextButtons: false,
                                    }}
                                >
                                    {artworkPhotos?.length > 0 &&
                                        artworkPhotos.map((photo, index) => {
                                            return (
                                                <div onClick={handleNavImageClick} className="item" key={index}>
                                                    <img src={photo.small} alt="artwork" />
                                                </div>
                                            );
                                        })}
                                    {photos3DModels?.url && <div className="item" onClick={() => {
                                        setIs3dViewOpen(false);
                                        handleModelClick(true);
                                    }}>
                                        <img src={photos3DModels?.small} alt="ThreeDbutton" />
                                    </div>}
                                    {
                                        !preview && (isOldExhibition || selectedArtwork?.data.exhibitionSceneId) &&
                                        <div className="item item-3d" onClick={() => {
                                            on3dButtonClick(selectedArtwork?.data.id);
                                            setIs3dViewOpen(true);
                                            setIs3dActive(true)
                                            handleModelClick(false);
                                        }}>
                                            <img src={ThreeDbutton} alt="ThreeDbutton" />
                                        </div>
                                    }
                                </Flickity>}
                            </section>
                        </div>
                    }
                </div>

            </div>
        </div>
    );
};

export default Artwork2DCarousel;
