import React, {useEffect} from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import GlobalPage from './containers/Global'
import store from './store/configureStore'
import './app.css';
import './styles/global.scss'
import './containers/Exhibitions/style.scss'
import logger from './utils/logger'

function App () {

  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--viewHeight', `${window.innerHeight}px`);
  }

  useEffect(() => {
      console.log('V 1.3 nurbs removal')
      const handleResize = () => {
        clearTimeout(resizeTimer); // Clear any existing timer
        resizeTimer = setTimeout(() => {
          appHeight(); // Call appHeight after a delay
        }, 1000); // Adjust the delay time as needed
      };

      let resizeTimer;

      appHeight();
      window.addEventListener('resize', handleResize)
      document.querySelector('body').classList.remove('bg-back-body');
      // Fixed the "Changes that you made may not be saved." Reload site Dialog
      window.onbeforeunload = null;
      return () => {
        window.removeEventListener('resize', handleResize);
      }
  }, []);

  return (
    <Provider store={store}>
      {/* <Router> */}
        <GlobalPage />
      {/* </Router> */}
      {/* <div style={{ margin: "20vh auto", display: 'flex', justifyContent: 'center' }}>
        <IslandNav variant='controls-to-search-and-filters' />
      </div> */}
    </Provider>
  )
}

export default App
