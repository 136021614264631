import {constructImageUrl, constructCFUrl} from "../../../utils";
export const getFilteredLinkedArtworks = (linkedArtworks, sceneId) => {
    return linkedArtworks.filter(({data}) => data.exhibitionSceneId == sceneId);
}
export const getFormattedListOfArtworks = (artworks) => {
    const assets = new Map();
    return Promise.all(artworks.map(async (artwork) => await getFormattedArtworkStructure(artwork, assets)))
}

export const getFormattedArtworkStructure = async ({data}, assets) => {
    const artworkType = data.artwork.data.type;
    let fileUrl;
    const mediaResource =
        artworkType === "2d"
            ? data.artwork.data.image
            : artworkType === "video"
                ? data.artwork.data.videoThumbnail
                : data.artwork.data.thumbnail;

    const thumbnailSignedUrl = data.artwork.data.thumbnail
        ? data.artwork.data.thumbnail.data.signedUrl
        : null;
    if (artworkType === "3d") {
        if (data.artwork.data.vorticHighBundleId) {
            fileUrl = constructCFUrl(data.artwork.data?.vorticHighBundle);
        } else if (data.artwork.data.vorticLowBundleId) {
            fileUrl = constructCFUrl(data.artwork.data?.vorticLowBundle);
        }
    } else if (artworkType === "2d") {
        fileUrl = mediaResource.data.signedUrl;
    } else if (artworkType === "video") {
        // TODO, handle in exhib details
        fileUrl = data.artwork.data.videoThumbnail.data.signedUrl;
    }

    const newImage = {};
    if (artworkType === "2d") {
        newImage["urlPNG"] = data.artwork.data.thumbnail
            ? data.artwork.data.thumbnail.data.signedUrl
            : null;
    }
    newImage["id"] = data?.id;
    newImage["url"] = fileUrl;
    newImage["thumbnail"] = thumbnailSignedUrl;
    newImage["title"] = data.artwork.data.title;
    newImage["artistName"] = data.artwork.data.artist.data.name;
    newImage["isFavourite"] = data.artwork.data.isFavourite;
    newImage["type"] = artworkType !== "3d" ? "2d" : "3d";
    newImage["squareURL"] = mediaResource && mediaResource.data && mediaResource.data.signedUrl;
    newImage["canvasColor"] = data.canvasColor || "#000000";
    newImage["isCanvas"] = data.isCanvas;
    newImage["isCollateral"] = data.isCollateral;
    newImage["skipLightmap"] = data.skipLightmap;

    var size = "1024sq_";

    newImage["basisURL"] = data?.artwork?.data?.basisURL
        ? data.artwork.data.basisURL
        : mediaResource?.data?.CFURL +
        size +
        mediaResource?.data?.name +
        ".basis" +
        mediaResource?.data?.signature;
    newImage["ktx2URL"] =  mediaResource?.data?.CFURL +
        "mipmap_q250_" +
        size +
        mediaResource?.data?.name +
        ".ktx2" +
        mediaResource?.data?.signature;

    newImage["previewImage"] =
        artworkType === "2d"
            ? mediaResource && mediaResource.data && constructImageUrl(mediaResource)
            : artworkType === "video"
                ? constructImageUrl(data.artwork.data.videoThumbnail)
                : constructImageUrl(data.artwork.data.thumbnail);
    newImage["artworkId"] = data.artworkId;
    newImage["frame"] = data.frame === "1" || data.frame === true;
    newImage["frameBack"] =
        data.frameBack === "1" || data.frameBack === true;
    newImage["frameGlass"] =
        data.frameGlass === "1" || data.frameGlass === true;
    newImage["trackingLight"] =
        data.trackingLight === "1" || data.trackingLight === true;
    if (assets.has(data.asset.data.id)) {
        newImage["asset"] = assets.get(data.asset.data.id);
    } else {
        assets.set(data.asset.data.id, data.asset);
        newImage["asset"] = data.asset;
    }
    newImage["onFloor"] = data.onFloor;
    newImage["lightmapURL"] = data.HDRArtworkMap
        ? data.HDRArtworkMap.data.signedUrl
        : null;
    newImage["pedestalLightMap"] = data.HDRAssetMap
        ? data.HDRAssetMap.data.signedUrl
        : null;
    newImage["frameSettings"] = {
        frameGap: data.frameGap,
        frameWidth: data.frameWidth,
        frameDepth: data.frameDepth,
        spotLight: data.spotLight,
        spotLightDistance: data.spotLightDistance,
        spotLightHeight: data.spotLightHeight,
        spotLightSoft: data.spotLightSoft,
        spotLightTemp: data.spotLightTemp,
        spotLightAngle: data.spotLightAngle,
    };
    newImage["dimensions"] = {
        height: parseFloat(data.artwork.data.height),
        width: parseFloat(data.artwork.data.width),
        depth: parseFloat(data.artwork.data.depth),
    };
    if (data.size) {
        newImage["size"] = data.size;
    }
    if (artworkType === "video") {
        newImage["video"] =
            data.artwork.data.video.data.signedMobileMedia;
    }
    if (data.x !== null && data.y !== null && data.z !== null) {
        newImage["visible"] = true;
        newImage["position"] = {
            x: data.x,
            y: data.y,
            z: data.z,
        };
        newImage["rotation"] = data.rotation;
        newImage["axisRotation"] = data.axisRotation;
        newImage["size"] = data.size;
    }


    newImage["ktx2URL_LOW_MIPMAP"] = mediaResource?.data?.CFURL +
        "mipmap_q250_" +
        size +
        mediaResource?.data?.name +
        ".ktx2" +
        mediaResource?.data?.signature;
    newImage["ktx2URL_HIGH_MIPMAP"] =mediaResource?.data?.CFURL +
        "mipmap_q250_" +
        "2048sq_" +
        mediaResource?.data?.name +
        ".ktx2" +
        mediaResource?.data?.signature;
    newImage["ktx2URL_LOW"] =mediaResource?.data?.CFURL +
        size +
        mediaResource?.data?.name +
        ".ktx2" +
        mediaResource?.data?.signature;
    newImage["ktx2URL_HIGH"] = mediaResource?.data?.CFURL +
        "2048sq_" +
        mediaResource?.data?.name +
        ".ktx2" +
        mediaResource?.data?.signature;
    return newImage;
}
