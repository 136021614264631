import React, {useEffect, useState} from 'react';
import { ArtworkView } from '@superbright/voritc-curate/build/';
//import { ArtworkView } from '/Users/igal/Code/vortic-curate/build/';
import Spinner from "../Spinner";
import logger from '../../utils/logger';

const Viewer = ({id, onFloor, modelUrl = ''}) => {
    logger.info('onFloor = ', onFloor)
    const parentId = `3d-model-${id}`;
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        let editor = new ArtworkView(parentId, modelUrl, () => {
            setIsLoaded(true);
            editor.resize();
        }, !onFloor);
        window.onresize = function () {
            editor.resize();
        };
    }, []);

    return (
        <div className={"model-container"} id={parentId}>
            {!isLoaded && <Spinner />}
        </div>
    )
}

export default Viewer;
