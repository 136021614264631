import React, { useEffect, useState, useRef } from 'react';
import "./NewsLetter.scss";
import IconBtn from '../IconBtn';
import Input from "../Input";
import Button from "../Button";
import { Paragraph } from "../Typography2";
import rudderStackEvents from "../../services/rudderstack-events";
import MailchimpSubscribe from "react-mailchimp-subscribe";

// Mailchimp Form URL
const MAILCHIMP_URL = "https://art.us18.list-manage.com/subscribe/post?u=d2b0372d82128412b1e7e606f&amp;id=69c6288fa2&amp;f_id=00eea9e6f0";

export default function NewsLetter({ handleClose, setCookie, isNewsLetterSubmited }) {
    const [isSubmit, setIsSubmit] = useState(false);
    const [nameErr, setNameErr] = useState(false);
    const [emailErr, setEmailErr] = useState(false);
    const [state, setState] = useState({ firstName: "", lastName: "", email: "" });
    const [steps, setSteps] = useState({ section1: false, section2: false });
    const [isHiding, setIsHiding] = useState(false);
    const [isFooterIn, setIsFooterIn] = useState(false);
    const timeoutRef = useRef(null);

    useEffect(() => {
        setSteps({
            section1: true,
            section2: true, // Ensure section2 is always visible
        });
    
        const handleScroll = () => {
            const footer = document.querySelector(".footer");
            if (footer) {
                const footerBounds = footer.getBoundingClientRect();
                const windowHeight = Math.ceil(window.innerHeight);
                setIsFooterIn(footerBounds.top < windowHeight);
            }
    
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => setIsFooterIn(false), 100);
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
        };
    }, []);

    const validateEmail = (email) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);

    const handleSubmit = (subscribe) => {
        if (!state.firstName.trim()) {
            setNameErr(true);
            return;
        }
        setNameErr(false);

        if (!state.email.trim() || !validateEmail(state.email)) {
            setEmailErr(true);
            return;
        }
        setEmailErr(false);

        setIsSubmit(true);
        rudderStackEvents.onJoiningMailingList('join_mailing_list');

        // Subscribe user using Mailchimp's API
        subscribe({ EMAIL: state.email, FNAME: state.firstName, LNAME: state.lastName || "" });

        setCookie();
        setTimeout(() => {
            setIsHiding(true);
            setTimeout(handleClose, 1000);
        }, 2000);

        setTimeout(() => setIsSubmit(false), 2000);
    };

    const handleChange = ({ target: { name, value } }) => {
        setState((prev) => ({ ...prev, [name]: value }));
        if (name === "firstName") setNameErr(!value.trim());
        if (name === "email") setEmailErr(!validateEmail(value));
    };

    useEffect(() => {
        console.log('isNewsLetterSubmited in component => ', isNewsLetterSubmited);
    }, [isNewsLetterSubmited])

    return (
        <MailchimpSubscribe
            url={MAILCHIMP_URL}
            render={({ subscribe, status, message }) => (
                <div className={`newsLetter ${isFooterIn ? "noSticky" : ""} ${isNewsLetterSubmited ? "small" : ""}`} 
                    style={{ ...(isNewsLetterSubmited && { justifyContent: "space-between" }), ...(isHiding && { opacity: 0 }) }}>
                    
                    <div className="newsLetter-top">
                        {(steps.section1 || isNewsLetterSubmited) && (
                            <Paragraph value={isNewsLetterSubmited ? "Thank you!" : "Join our mailing list to learn about upcoming exhibitions from across the world."} />
                        )}
                        <div className="buttonContainer buttonContainer-mobile-view">
                            <IconBtn className="closeButton" type="cross" onClick={handleClose} />
                        </div>
                    </div>

                    {!isNewsLetterSubmited && (
                        <div className="input-container">
                            {steps.section2 && (
                                <>
                                    <Input 
                                        name="firstName" 
                                        onChange={handleChange} 
                                        placeholder="First name" 
                                        type="text" 
                                        errorMsg={nameErr} 
                                        active 
                                    />
                                    <Input 
                                        name="lastName" 
                                        onChange={handleChange} 
                                        placeholder="Last name" 
                                        type="text" 
                                        active 
                                    />
                                    <Input 
                                        name="email" 
                                        onChange={handleChange} 
                                        placeholder="Email address" 
                                        type="email" 
                                        errorMsg={emailErr} 
                                        active 
                                    />
                                </>
                            )}

                            {steps.section2 && (
                                <Button 
                                    value={isSubmit ? 'Submitting...' : 'Submit'} 
                                    iconType="submit"
                                    disable={!state.firstName.trim() || !validateEmail(state.email) || isSubmit}
                                    className="newLetterButton"
                                    onClick={() => handleSubmit(subscribe)} 
                                />
                            )}

                            {/* Hidden bot protection field */}
                            <form style={{ display: 'none' }} data-hs-cf-bound="false" className='hs-skip-form-binding'>
                                <input type="text" name="b_d2b0372d82128412b1e7e606f_69c6288fa2" tabIndex={-1} value="" readOnly />
                            </form>

                            {/* Show messages based on Mailchimp API response */}
                            {status === "sending" && <p style={{ color: "blue" }}>Submitting...</p>}
                            {status === "error" && <p style={{ color: "red" }}>{message}</p>}
                            {status === "success" && <p style={{ color: "green" }}>Subscribed!</p>}

                            <div className="buttonContainer">
                                <IconBtn className="closeButton" type="cross" onClick={handleClose} />
                            </div>
                        </div>
                    )}
                </div>
            )}
        />
    );
}